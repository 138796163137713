<template>
  <div>
    <b-card no-body class="card-custom gutter-b" header-class="d-flex align-items-center">
      <template v-slot:header>
        <div class="contacts-header-toolbar">
          <b-row>
            <b-col sm="3" class="my-2 my-md-0">
              <div class="input-icon ">
                <b-form-input type="text" placeholder="Search..." v-model="filters.query" @input="search" />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </b-col>
            <b-col sm="3" class="my-2 my-md-0">
              <div class="d-flex align-items-center">
                <label class="mr-3 mb-0 d-none d-md-block">Types:</label>
                <b-form-select v-model="filters.contact_type" :options="withViewAll(contactTypes)" @change="handleSelelction('contact_type', filters.contact_type)" />
              </div>
            </b-col>
            <b-col v-if="isCoordinator" sm="3" class="my-2 my-md-0">
              <div class="d-flex align-items-center">
                <label class="mr-3 mb-0 d-none d-md-block">Cooperatives:</label>
                <b-form-select v-model="filters.cooperative_id" :options="withViewAll(cooperatives)" @change="handleSelelction('cooperative_id', filters.cooperative_id)" />
              </div>
            </b-col>
            <b-col :sm="isCoordinator ? 2 : 3" class="ml-auto text-right my-2 my-md-0">
              <b-btn variant="light-primary" :class="{ active: isGrid }" @click="changeLayout()">
                <i class="fas fa-th pr-0" />
              </b-btn>
              <b-btn variant="light-primary" class="ml-3" :class="{ active: !isGrid }" @click="changeLayout()">
                <i class="fas fa-th-list pr-0" />
              </b-btn>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
    <div v-if="isGrid">
      <template v-if="!gridLoading">
        <b-row>
          <b-col v-for="(contact, $index) in items" :key="$index" sm="6" xl="3">
            <KTContactsSingle
              :name="`${contact.attributes.first_name} ${contact.attributes.last_name}`"
              :email="contact.attributes.email"
              :phone="contact.attributes.phone"
              :avatar="contact.attributes.avatar"
              :cooperative="get(contact, 'attributes.cooperative.data.attributes.company_name')"
              :role="contact.attributes.contact_type"
            />
          </b-col>
        </b-row>
        <div v-if="loadingLoadMore || pagination.last_page > pagination.page" class="text-center">
          <b-btn variant="primary" class="px-15" v-cs-loading="loadingLoadMore" @click="onLoadMore()">
            Load More
          </b-btn>
        </div>
      </template>
      <template v-else>
        <div class="position-relative" style="height:400px;">
          <spinner bg-transparent />
        </div>
      </template>
    </div>

    <div v-else>
      <cs-table :loading="loading" :headers="headers" :items="items" :pagination="pagination" @filtered="handelFiltering" hide-header>
        <template #[`item.first_name`]="{item}">
          <div class="d-flex align-items-center">
            <!--begin::Pic-->
            <div class="flex-shrink-0 mr-4 mt-lg-0 mt-3">
              <cs-avatar
                classes="symbol symbol-lg-40 symbol-circle font-size-h6 font-weight-boldest"
                :src="get(item, 'attributes.avatar')"
                :name="`${get(item, 'attributes.first_name')} ${get(item, 'attributes.last_name')}`"
              ></cs-avatar>
            </div>
            <!--end::Pic-->
            <!--begin::Title-->
            <div class="d-flex flex-column">
              <span class="text-dark font-weight-bold font-size-h6">
                {{ `${get(item, 'attributes.first_name')} ${get(item, 'attributes.last_name')}` }}
              </span>
              <span class="text-muted font-weight-bold">
                {{ get(item, 'attributes.cooperative.data.attributes.company_name') }}
              </span>
            </div>
            <!--end::Title-->
          </div>
        </template>
        <template #[`item.email`]="{item}">
          <a :href="`mailto:${get(item, 'attributes.email')}`" class="text-muted text-hover-primary">
            {{ get(item, 'attributes.email') }}
          </a>
        </template>
        <template #[`item.phone`]="{item}">
          <a :href="`tel:${get(item, 'attributes.phone')}`" class="text-muted text-hover-primary">
            {{ get(item, 'attributes.phone') }}
          </a>
        </template>
        <template #[`item.role`]="{item}">
          <template>
            <span :class="['label-light-' + (get(item, 'attributes.contact_type') == 'Applicant' ? 'warning' : 'primary')]" class="label font-weight-bold label-lg label-inline">
              {{ get(item, 'attributes.contact_type') }}
            </span>
          </template>
        </template>
      </cs-table>
    </div>
  </div>
</template>

<script>
import KTContactsSingle from '@/components/generic/ContactsSingle';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { debounce } from 'lodash';

export default {
  name: 'contacts',
  components: {
    KTContactsSingle,
  },
  data() {
    return {
      isGrid: this.$route.query.view != 'table',
      gridLoading: false,
      loading: false,
      loadingLoadMore: false,
      contactTypes: [
        { text: 'Cooperative', value: 'Cooperative' },
        { text: 'Applicant', value: 'Applicant' },
        { text: 'Seller', value: 'Seller' },
        { text: 'Board Member', value: 'Board Member' },
        { text: 'Managing Agent', value: 'Managing Agent' },
        { text: 'Transfer Agent', value: 'Transfer Agent' },
        { text: 'Insurance Broker', value: 'Insurance Broker' },
        { text: 'Accounts Receivable', value: 'Accounts Receivable' },
      ],
      filters: {
        cooperative_id: null,
        contact_type: null,
        query: '',
      },
      headers: [
        {
          text: 'Name',
          value: 'first_name',
          sortable: true,
        },
        {
          text: 'Email',
          value: 'email',
          // sortable: true,
          align: 'center',
        },
        {
          text: 'Phone',
          value: 'phone',
          align: 'center',
        },
        {
          text: 'Type',
          value: 'role',
          align: 'right',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      items: 'contacts/items',
      cooperatives: 'resources/cooperatives',
      pagination: 'contacts/pagination',
    }),
  },
  async mounted() {
    const query = this.$route.query;
    this.filters = {
      query: query.query || '',
      contact_type: query.contact_type || null,
      cooperative_id: query.cooperative_id || null,
    };

    if (this.cooperatives.length == 0 && this.isCoordinator) await this.$await(this.getCooperatives());

    this.init();
  },
  methods: {
    ...mapActions({
      getContacts: 'contacts/index',
      getCooperatives: 'resources/cooperatives',
    }),
    ...mapMutations({
      updatePaginationPage: 'contacts/UPDATE_PAGINATION_PAGE',
    }),

    withViewAll(data) {
      return [{ text: 'View All', value: null }, ...data];
    },

    async handelFiltering(filters) {
      const routeQuery = this.$route.query;

      filters = this.checkRouteForParams('contact_type', this.filters.contact_type, filters);

      filters = this.checkRouteForParams('cooperative_id', this.filters.cooperative_id, filters);

      filters = this.checkRouteForParams('query', this.filters.query, filters);

      const isSame = this.isSame(routeQuery, filters);

      if (isSame) return;

      await this.$router.replace({ query: filters });

      await this.handleContacts();
    },

    handleContacts: debounce(async function(e) {
      this.loading = true;

      const query = this.$_.cloneDeep(this.$route.query);
      query.elasticsearch = true;

      if (query.where == undefined) query.where = {};

      if (query.contact_type != undefined) query.where.contact_type = query.contact_type;

      if (query.cooperative_id != undefined) query.where.cooperative_id = query.cooperative_id;

      if (query.per == undefined) query.per = 10;

      if (query.per == -1) query.per = Math.pow(10, 12);

      await this.$await(this.getContacts(query));

      this.loading = false;
    }, 400),

    handleContactsGrid: debounce(async function(gridLoading = true, cb = null) {
      if (gridLoading) this.gridLoading = true;

      const params = { page: this.pagination.page, per: 20, elasticsearch: true, infinite: 1, where: {} };

      const query = this.$_.cloneDeep(this.$route.query);

      if (query.contact_type != undefined) params.where.contact_type = query.contact_type;

      if (query.cooperative_id != undefined) params.where.cooperative_id = query.cooperative_id;

      if (query.query != undefined) params.query = query.query;

      await this.$await(this.getContacts(params));

      if (gridLoading) this.gridLoading = false;
      if (typeof cb == 'function') cb();
    }, 400),

    async handleSelelction(key, val) {
      if (this.isGrid) {
        const filters = this.checkRouteForParams(key, val);

        await this.$router.replace({ query: filters });

        this.updatePaginationPage({ page: 1 });

        this.handleContactsGrid();
      } else {
        const filters = this.checkRouteForParams('page', 1);
        this.handelFiltering(filters);
      }
    },

    search: debounce(async function(e) {
      this.handleSelelction('query', this.filters.query);
    }, 700),

    checkRouteForParams(key, val, query = this.$route.query) {
      const filters = this.$_.cloneDeep(query);
      if (!val && filters[key] != undefined) delete filters[key];

      if (val) filters[key] = val;

      return filters;
    },

    async changeLayout() {
      this.isGrid = !this.isGrid;
      await this.clearRouteQueries(this.isGrid);

      if (this.isGrid) {
        this.updatePaginationPage({ page: 1 });
        await this.handleContactsGrid();
      } else {
        this.handleContacts();
      }
    },

    async clearRouteQueries(isGrid = undefined) {
      const routeQuery = this.$_.cloneDeep(this.$route.query);
      const query = this.$_.cloneDeep(this.$route.query);

      if (routeQuery.per != undefined) delete routeQuery.per;
      if (routeQuery.page != undefined) delete routeQuery.page;
      if (routeQuery.order_by != undefined) delete routeQuery.order_by;
      if (routeQuery.order != undefined) delete routeQuery.order;

      if (isGrid !== undefined) {
        if (isGrid && routeQuery.view != undefined) {
          delete routeQuery.view;
        } else {
          routeQuery.view = 'table';
        }
      }

      if (!this.isSame(routeQuery, query)) await this.$router.replace({ query: routeQuery });
    },

    async init() {
      if (this.isGrid) {
        this.updatePaginationPage({ page: 1 });
        await this.clearRouteQueries();
        await this.handleContactsGrid();
      } else {
        this.handleContacts();
      }
    },

    async onLoadMore() {
      this.loadingLoadMore = true;
      this.updatePaginationPage({ page: this.pagination.page + 1 });

      this.handleContactsGrid(false, () => {
        this.loadingLoadMore = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.contacts-header-toolbar {
  width: 100%;
  display: grid;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
