<template>
  <b-card class="card-custom card-stretch gutter-b" body-class="d-flex flex-column p-4">
    <!--begin::User-->
    <div class="d-flex align-items-end mb-7">
      <div class="d-flex align-items-center">
        <!--begin::Pic-->
        <div class="flex-shrink-0 mr-4 mt-lg-0 mt-3">
          <cs-avatar classes="symbol symbol-circle symbol-lg-75 font-size-h3 font-weight-boldest" :src="avatar" :name="name"></cs-avatar>
        </div>
        <!--end::Pic-->
        <!--begin::Title-->
        <div class="d-flex flex-column word-break">
          <span class="text-dark font-weight-bold font-size-h4">
            {{ name }}
          </span>
        </div>
        <!--end::Title-->
      </div>
    </div>
    <!--end::User-->
    <p class="mb-7">{{ cooperative }}</p>
    <!--begin::Info-->
    <div class="mb-7">
      <div v-if="email" class="d-flex justify-content-between align-items-center">
        <span class="text-dark-75 font-weight-bolder mr-2">Email:</span>
        <a :href="`mailto:${email}`" class="text-muted text-hover-primary">
          {{ email }}
        </a>
      </div>
      <div v-if="phone" class="d-flex justify-content-between align-items-cente my-1">
        <span class="text-dark-75 font-weight-bolder mr-2">Phone:</span>
        <a :href="`tel:${phone}`" class="text-muted text-hover-primary">
          {{ phone }}
        </a>
      </div>
    </div>
    <!--end::Info-->
    <div class="mt-auto">
      <b-btn :variant="'light-' + (role == 'Applicant' ? 'warning' : 'primary')" size="sm" tag="span" block class="btn-text cursor-default font-weight-bolder text-uppercase py-4">
        {{ role }}
      </b-btn>
    </div>
  </b-card>
</template>

<script>
import colorsMixin from '@/mixins/colors-mixin';
export default {
  mixins: [colorsMixin],
  name: 'KTContactsSingle',
  props: {
    name: String,
    email: String,
    phone: String,
    avatar: String,
    cooperative: String,
    role: String,
  },
};
</script>
